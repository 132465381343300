@use '@angular/material' as mat;
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400&display=swap');

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

*{
  font-family: 'Montserrat', sans-serif;
}

html, body { height: 100%; }
body { margin: 0;}

mat-icon{
  font-family: 'Material Icons' !important;
}

::ng-deep .mat-drawer-side {
  border-right: none !important;
}

.snackbar {
  background: #0047BB !important;
  color: white;
}

$primaryColor: #0047BB;
$accentColor: #71DBD4;
$warningColor: #CB333B;
$darkBlueColor: #6CACE4;
$greyColor: #868388;

@include mat.core();

$md-primary: (
  50 : #e5e5ec,
  100 : #bdbed0,
  200 : #9293b1,
  300 : #666792,
  400 : #45477a,
  500 : #242663,
  600 : #20225b,
  700 : #1b1c51,
  800 : #161747,
  900 : #0d0d35,
  A100 : #7171ff,
  A200 : #3e3eff,
  A400 : #0b0bff,
  A700 : #0000f1,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$md-accent: (
  50 : #f0faf6,
  100 : #d9f3e7,
  200 : #c0ecd8,
  300 : #a6e4c8,
  400 : #93debc,
  500 : #80d8b0,
  600 : #78d4a9,
  700 : #6dcea0,
  800 : #63c897,
  900 : #50bf87,
  A100 : #ffffff,
  A200 : #ebfff4,
  A400 : #b8ffda,
  A700 : #9effcd,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$md-warning: (
    50 : #f9e7e7,
    100 : #efc2c4,
    200 : #e5999d,
    300 : #db7076,
    400 : #d35258,
    500 : #cb333b,
    600 : #c62e35,
    700 : #be272d,
    800 : #b82026,
    900 : #ac1419,
    A100 : #ffdede,
    A200 : #ffabac,
    A400 : #ff787a,
    A700 : #ff5e61,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$app-theme: mat.define-light-theme(
    mat.define-palette($md-primary),
    mat.define-palette($md-accent), 
    mat.define-palette($md-warning));

  .bg-primary{
    background-color: $primaryColor !important
  }
  .bg-danger{
      background-color: $warningColor;
  }
  .bg-accent{
    background-color: $accentColor !important
  }

  .text-danger{
    color: #BB2532 !important;
  }
  .text-light-danger{
    color: #F22C44 !important;
  }
  .text-primary{
    color: #242663  !important;
  }
  .text-success{
    color: #2B8741 !important;
  }

  .btn-primary {
    width: 100%;
    padding: 12px 0;
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 21px;
    color: #E5E5E5;
    background: #1563B0 ;
    border-radius: 100px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 60px;

    &:disabled {
      background: #52687370;
      cursor: default;
    }
    &:not([disabled]):hover {
      background-color: #05417B;
      transition: 200ms;
    }
  }

  .btn-secondary{
    width: 100%;
    padding: 12px 0;
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 21px;
    color: #F22C44;
    background: transparent;
    border-radius: 8px;    
    border: 2px solid #F22C44;
    outline: none;
    cursor: pointer;
    margin-top: 60px;
  }

  .btn-secondary:hover{
    background: #F22C44;
    color: #FFF;
    transition: 200ms 
  }  

 

.snackbar-alert{
  background-color: #1563B0  !important;
  color: #FFF !important;
  font-weight: 600;
  animation: shake !important;
}

input, textarea, select{
  @apply border rounded-lg px-3 py-2 mt-1 text-sm w-full bg-white focus:border-primary-500 focus:outline-none focus:ring-primary-500;

  &:disabled{
    @apply border-neutral-light-grey-700 text-gray-400 cursor-not-allowed;
  }
}

select{
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.btn-swal{
  background-color: #242663 !important;
  color: white;
  margin: .3125em;
  padding: .625em 1.1em;
  transition:  box-shadow .1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
  order: null;
  border: 0;
  border-radius: .25em;
  background: initial;
  font-size: 1em;

  &:not([disabled]) {
    cursor: pointer;
  }

  &:focus {
      outline: none;
  }

  &.cancel {
    background-color: transparent !important;
    color: #CB333B
  }
}

@include mat.all-component-themes($app-theme);
